import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import StoryblokTemplate from "~templates/storyblok.jsx";
import { breakpoint } from "~utils/css.js";

/** ============================================================================
 * @component
 * Product graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 * todo: custom seo
 */
const StoryblokProduct = ({ data, location, draftBridge }) => {
  const {
    storyblokEntry: { name }
  } = data;

  return (
    <StoryblokTemplate
      css={css`
        padding-top: var(--header-height-touch);

        ${breakpoint(`large-tablet`)} {
          padding-top: calc(
            var(--header-height-desktop) + var(--case-study-nav-height-desktop)
          );
        }
      `}
      data={data}
      location={location}
      draftBridge={draftBridge}
      components={[
        {
          component: `ProductNav`
        }
      ]}
    />
  );
};
export default StoryblokProduct;

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id

      full_slug
      name
      content
    }
  }
`;
