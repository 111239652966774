import React from "react";
import { graphql } from "gatsby";
import StoryblokTemplate from "~templates/storyblok.jsx";

/** ============================================================================
 * @component
 * Article graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 */
const StoryblokPress = ({ data, location, draftBridge }) => {
  const storyblokContent =
    typeof data?.storyblokEntry?.content === `object`
      ? data?.storyblokEntry?.content
      : JSON.parse(data?.storyblokEntry?.content);

  const storyblokSeo = storyblokContent?.seo;

  const pressBlok = storyblokContent.bloks?.[0]
    ? storyblokContent.bloks?.find(
        (blok) => blok?.component === `BlokPressBody`
      )
    : [];

  const firstParagraph = pressBlok?.body
    ? pressBlok?.body?.content?.find(
        (contentItem) => contentItem.type === `paragraph`
      )
    : null;

  const seoDescription = firstParagraph?.content?.[0]
    ? firstParagraph?.content?.[0].text
    : null;

  const seoTitle = `${storyblokContent?.title} | Press Release | DIRTT`;

  const seo = {
    ...storyblokSeo,
    title: storyblokSeo?.title || seoTitle,
    description: storyblokSeo?.description || seoDescription
  };

  const pageData = {
    ...data,
    storyblokEntry: {
      content: {
        ...storyblokContent,
        seo
      }
    }
  };

  return (
    <StoryblokTemplate
      data={pageData}
      location={location}
      draftBridge={draftBridge}
      components={[{ component: `PressBanner` }]}
    />
  );
};

export default StoryblokPress;

export const query = graphql`
  query Press($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id

      full_slug
      name
      content
    }
  }
`;
