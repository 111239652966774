/* eslint-disable camelcase */

import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useApp } from "~hooks";
import StoryblokTemplate from "~templates/storyblok.jsx";

const BREADCRUMB_ROOTS = {
  resources: []
};

/** ============================================================================
 * @component
 * Static Page graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 */
const StoryblokInsight = ({ data, location, draftBridge }) => {
  const { setInsightsTheme } = useApp();

  const {
    storyblokEntry: { full_slug }
  } = data;

  //

  useEffect(() => {
    if (!data?.storyblokEntry?.content?.theme) {
      return;
    }

    setInsightsTheme(data.storyblokEntry.content.theme);
  }, [data?.storyblokEntry]);

  //

  let rootLabel;

  Object.keys(BREADCRUMB_ROOTS).forEach((rootKey) => {
    if (rootLabel !== undefined) return;

    // check if the page slug matches any of the defined pages inside BREADCRUMBS_ROOTS
    const pageMatched = BREADCRUMB_ROOTS[rootKey].find(
      (element) => element === full_slug.toLowerCase()
    );

    if (pageMatched) {
      rootLabel = rootKey;
    }
  });

  return (
    <StoryblokTemplate
      data={data}
      draftBridge={draftBridge}
      location={location}
      toast="Copied to clipboard"
      components={[
        {
          component: `Breadcrumbs`,
          extraTabletPadding: true,
          rootLabel
        },
        {
          component: `InsightsArticleHero`
        }
      ]}
    />
  );
};

export default StoryblokInsight;

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id
      full_slug
      name

      content
    }
  }
`;
