import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { useStoryblok } from "lam-gatsby-source-storyblok";
import { Button, Layout } from "~components";

import StoryblokArticle from "~templates/article.jsx";
import StoryblokBlog from "~templates/blog.jsx";
import StoryblokInsightIdeaStarter from "~templates/insight-idea-starter.jsx";
import StoryblokInsightSeries from "~templates/insight-series.jsx";
import StoryblokInsight from "~templates/insight.jsx";
import StoryblokInsights from "~templates/insights.jsx";
import StoryblokInvestor from "~templates/investor.jsx";
import StoryblokMarketListing from "~templates/mlp.jsx";
import StoryblokPage from "~templates/page.jsx";
import StoryblokPress from "~templates/press.jsx";
import StoryblokProduct from "~templates/product.jsx";
import StoryblokProject from "~templates/project.jsx";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/** ============================================================================
 * @component
 * 404 page.
 */
const PageNotFound = ({ location }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [pageTemplate, setPageTemplate] = useState(null);

  const storyblokEntry = useStoryblok({}, location, {
    previewUrl: process.env.GATSBY_STORYBLOK_PREVIEW_URL
  });

  useEffect(() => {
    const pageType = storyblokEntry?.content?.component;

    let template = null;

    switch (pageType?.toLowerCase()) {
      case `article`:
        template = (
          <StoryblokArticle
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `blog`:
        template = (
          <StoryblokBlog
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `insight-idea-starter`:
        template = (
          <StoryblokInsightIdeaStarter
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );

        break;

      case `insight-series`:
        template = (
          <StoryblokInsightSeries
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `insight`:
        template = (
          <StoryblokInsight
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `insights`:
        template = (
          <StoryblokInsights
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `investor`:
        template = (
          <StoryblokInvestor
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `mlp`:
        template = (
          <StoryblokMarketListing
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `page`:
        template = (
          <StoryblokPage
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `plp`:
        template = (
          <StoryblokMarketListing
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `press`:
        template = (
          <StoryblokPress
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `product`:
        template = (
          <StoryblokProduct
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      case `project`:
        template = (
          <StoryblokProject
            data={{ storyblokEntry }}
            location={location}
            draftBridge
          />
        );
        break;

      default:
        break;
    }

    setPageTemplate(template);
  }, [storyblokEntry]);

  // ---------------------------------------------------------------------------
  // render

  if (!pageTemplate) {
    return (
      <Layout>
        <Container>
          <h2 className="h1">This page doesn't exist.</h2>

          <Link to="/">
            <Button title="Back to home">
              <span>Back to home</span>
            </Button>
          </Link>
        </Container>
      </Layout>
    );
  }

  return pageTemplate;
};

export default PageNotFound;
