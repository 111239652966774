import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import StoryblokTemplate from "~templates/storyblok.jsx";

// todo: @lane

/** ============================================================================
 * @component
 * Blog graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 */
const StoryblokBlog = ({ data, location, draftBridge }) => (
  <StoryblokTemplate
    css={css`
      padding-top: calc(
        var(--header-height-desktop) + var(--case-study-nav-height-desktop)
      );
    `}
    data={data}
    draftBridge={draftBridge}
    location={location}
  />
);

export default StoryblokBlog;

export const query = graphql`
  query Blog($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id

      full_slug
      name
      content
    }
  }
`;
