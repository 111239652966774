import React from "react";
import { graphql } from "gatsby";
import StoryblokTemplate from "~templates/storyblok.jsx";

/** ============================================================================
 * @component
 * Article graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 */
const StoryblokArticle = ({ data, location, draftBridge }) => (
  <StoryblokTemplate
    data={data}
    location={location}
    draftBridge={draftBridge}
    components={[{ component: `ArticleBanner` }]}
  />
);

export default StoryblokArticle;

export const query = graphql`
  query Article($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id

      full_slug
      name
      content
    }
  }
`;
