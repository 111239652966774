/* eslint-disable camelcase */

import React from "react";
import { graphql } from "gatsby";
import StoryblokTemplate from "~templates/storyblok.jsx";

const BREADCRUMB_ROOTS = {
  resources: []
};

const BREADCRUMB_STYLES = {
  resources: {
    background: `grey-10`,
    color: `grey-70`
  }
};

/** ============================================================================
 * @component
 * Static Page graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 */
const StoryblokPage = ({ data, location, draftBridge }) => {
  const {
    storyblokEntry: { full_slug }
  } = data;

  let rootLabel;
  let breadcrumbStyles = {
    background: `white`,
    color: `grey-70`
  };

  Object.keys(BREADCRUMB_ROOTS).forEach((rootKey) => {
    if (rootLabel !== undefined) return;

    // check if the page slug matches any of the defined pages inside BREADCRUMBS_ROOTS
    const pageMatched = BREADCRUMB_ROOTS[rootKey].find(
      (element) => element === full_slug.toLowerCase()
    );

    if (pageMatched) {
      rootLabel = rootKey;
    }
  });

  Object.keys(BREADCRUMB_STYLES).forEach((styleKey) => {
    if (BREADCRUMB_STYLES?.[styleKey] !== `undefined`) {
      breadcrumbStyles = BREADCRUMB_STYLES[styleKey];
    }
  });

  return (
    <StoryblokTemplate
      data={data}
      draftBridge={draftBridge}
      location={location}
      components={[
        {
          component: `Breadcrumbs`,
          background: breadcrumbStyles.background,
          color: breadcrumbStyles.color,
          rootLabel
        }
      ]}
    />
  );
};

export default StoryblokPage;

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id
      full_slug
      name

      content
    }
  }
`;
